.docMainContainer {
  height: 100vh;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.docSubContainer {
  padding: 10% 20%;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.docSubContainer h1 {
  font-size: 54px;
  font-family: "Ubuntu", sans-serif;
  color: #04a188;
  font-weight: 600;
}

.docSubContainer p {
  font-size: 18px;
  line-height: 1.4;
  font-family: "Ubuntu", sans-serif;
}
