@import url("https://fonts.googleapis.com/css2?family=Montserrat+Alternates:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Noto+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&family=Ubuntu+Mono:ital,wght@0,400;0,700;1,400;1,700&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap");
.HomeMainContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
  font-family: "Montserrat Alternates", sans-serif;
  font-family: "Ubuntu", sans-serif;
  /* font-family: 'Ubuntu Mono',
  monospace; */
  font-family: "PT Sans", sans-serif;
  font-family: "Noto Sans", sans-serif;
}
.HomeSubContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.npmPackage {
  position: absolute;
  bottom: -35px;
  height: 70px;
  width: auto;
  background-color: #fff;
  border-radius: 70px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  font-size: 1.2rem;
  padding: 0px 30px;
  font-weight: 500;
  box-shadow: #03be9f83 0px 80px 120px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  transition: all ease-in-out 0.3s;
}
.npmPackage > a {
  color: #000;
}
.npmPackage:hover {
  transform: scale(1.1);
  transition: all ease-in-out 0.3s;
  cursor: pointer;
}
.npmPackage img {
  height: 2rem;
}
.brandSection span {
  font-size: 2.1rem;
  font-weight: 700;
  color: #04a188;
}
.HeaderMainContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f7f7f7;
  position: relative;
}
.HeaderContainer {
  width: 1200px;
}
.navigationBar {
  max-width: 1200px;
  width: 100%;
  padding: 10px 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.linkSection {
  display: flex;
  align-items: center;
  justify-content: end;
  font-size: 1.1rem;
  width: 30%;
  text-align: right;
}
.linkSection > a {
  color: #000;
}
a {
  text-decoration: none !important;
  font-weight: 500;
  display: flex;
  justify-items: center;
}
.brandSection img {
  width: 2rem;
}
.HeaderContent {
  padding: 50px 0px;
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
}
.HeaderContent .imageSection img {
  width: 700px;
}
.HeaderContainer .textHeading {
  font-family: "Montserrat", sans-serif;
  font-family: "Montserrat Alternates", sans-serif;
  font-family: "Ubuntu", sans-serif;
  /* font-family: 'Ubuntu Mono',
  monospace; */
  font-family: "PT Sans", sans-serif;
  font-family: "Noto Sans", sans-serif;
  font-size: 3.5rem;
  font-weight: 800;
  line-height: 4rem;
  color: #04a188;
  margin: 50px 0px 30px;
}
.HeaderContainer .textContent {
  width: 400px;
  font-size: 1.2rem;
  margin-bottom: 30px;
  font-weight: 500;
}
.HeaderContainer .buttonSection button {
  width: 15rem;
  height: 3rem;
  --buttonColorBottom: #04a188;
  --buttonColor: #04a188;
}

/* //!About Section */
.aboutSection {
  padding: 200px 0px;
  background: #04a188;
  background-color: #04a188;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.aboutSectionSubContainer {
  max-width: 1200px;
  width: 100%;
  display: grid;
  grid-gap: 30px;
  grid-template-columns: auto auto;
  justify-content: space-between;
}

.aboutSection .leftSection .textHeading {
  font-size: 3.5rem;
  font-weight: 800;
  color: #fff;
  -webkit-text-stroke: 2px #06c7a7ce;
}

.aboutSection .leftSection .textContent {
  margin: 10px 0px;
  color: white;
  width: 600px;
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 2.2rem;
}
.aboutSection .rightSection {
  display: flex;
  flex-direction: column;
  justify-content: right;
  align-items: flex-end;
}
.aboutSection .rightSection .textHeading {
  font-size: 3.2rem;
  font-weight: 800;
  color: #fff;
  width: 400px;
}
.aboutSection .rightSection .textContent {
  margin: 10px 0px;
  color: #fff;
  width: 500px;
}
.aboutSection .rightSection .buttonSection {
  display: flex;
  justify-content: center;
}
.aboutSection .rightSection .buttonSection button {
  --buttonColorBottom: #f1f5f4;
  --buttonColor: #ffffff;
  width: 300px;
}

/* CSS */
.button-19 {
  appearance: button;
  background-color: #04a187ab;
  border: solid transparent;
  border-radius: 16px;
  border-width: 0 0 4px;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  font-family: din-round, sans-serif;
  font-size: 15px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 20px;
  margin: 0;
  outline: none;
  overflow: visible;
  padding: 13px 16px;
  text-align: center;
  text-transform: uppercase;
  touch-action: manipulation;
  transform: translateZ(0);
  transition: filter 0.2s;
  user-select: none;
  -webkit-user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  width: 500px;
}
.button-20 {
  appearance: button;
  background-color: #07d8b6;
  border: solid transparent;
  border-radius: 16px;
  border-width: 0 0 4px;
  box-sizing: border-box;
  color: #ffffff !important;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: din-round, sans-serif;
  font-size: 15px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 20px;
  margin: 0;
  outline: none;
  overflow: visible;
  padding: 13px 16px;
  text-align: center;
  text-transform: uppercase;
  touch-action: manipulation;
  transform: translateZ(0);
  transition: filter 0.2s;
  user-select: none;
  -webkit-user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  width: 100%;
}

.button-20 > a {
  color: #ffffff !important;
  font-weight: 600;
}
.button-20:hover {
  background-color: #05bb9c;
  transition: all 0.1s ease-in-out;
}

.button-19:after {
  /* background-clip: padding-box;
  background-color: #04a188;
  border: solid transparent;
  border-radius: 16px;
  border-width: 0 0 4px;
  bottom: -4px;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1; */
}

.button-19:main,
.button-19:focus {
  user-select: auto;
}

.button-19:hover:not(:disabled) {
  filter: brightness(1.1);
}

.button-19:disabled {
  cursor: auto;
}
.testSection1,
.testSection2 {
  padding: 0px 0px 100px;
  position: relative;
}
.testSection1 {
  background-color: #fbf8f1;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.testSectionContent {
  width: 1200px;
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 50px;
}
.testSection1 .testSectionContent .leftSection {
  font-size: 1.1rem;
  background-color: #04a18783;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 55px;
  border-radius: 15px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.testSection1 .testSectionContent .leftSection .testHeading {
  font-size: 2rem;
  font-weight: 600;
}
.testSection1 .testSectionContent .leftSection .subText {
  text-align: center;
}
.testSection1 .testSectionContent .rightSection {
  padding: 50px 30px;
}
.boxDiv {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.boxDivUnit {
  width: 50px;
  height: 50px;
  margin: 5px;
  border-radius: 5px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
}
.boxIndexUnit {
  display: flex;
  justify-content: center;
  align-items: center;
}
.fieldInput {
  margin: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.fieldInput input {
  width: 500px;
  height: 50px;
  border-radius: 50px;
  border: none;
  transition: all 0.1s ease;
  padding: 0px 25px;
  font-size: 1.2rem;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.fieldInput input:focus {
  outline: 3px solid #04a188;
  transition: all 0.1s ease;
}
.testSection1 .buttonSection {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.testSection1 button {
  --buttonColorBottom: #04a188;
  --buttonColor: #04a188;
  width: 250px;
}

.testSection2 {
  background-color: #fff;
  background-color: #04a18757;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.testSection2 .testSectionContent {
  width: 1200px;
}
.testSection2 .leftSection {
  padding: 50px 30px;
}
.testSection2 .rightSection {
  background-color: #d3fadd95;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 55px;
  font-size: 1.2rem;
  border-radius: 15px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.testSection2 .subText {
  text-align: center;
}
.testSection2 .buttonSection {
  display: flex;
  justify-content: center;
  align-items: center;
}
.testSection2 button {
  --buttonColorBottom: #019267;
  --buttonColor: #00c78b;
  width: 20rem;
}

.overLayText1 {
  text-align: center;
  font-size: 3rem;
  font-weight: 800;
  color: #fff;
  backdrop-filter: blur(5px);
  -webkit-text-stroke: 2px #04a188;
  /* width: 100%; */
  padding: 30px;
}
.overLayText2 {
  text-align: center;
  font-size: 3rem;
  font-weight: 800;
  color: #04a188;
  backdrop-filter: blur(5px);
  /* width: 100%; */
  padding: 30px;
  font-weight: 800;
}
.summaryText {
  margin: 0px 0px 50px 0px;
  padding: 0px 20px;
  font-size: 1.1rem;
  text-align: center;
  font-weight: 500;
  border-radius: 15px;
}
.bloomContent {
  font-size: 1.4rem;
  font-weight: 500;
}
.statusGreen {
  background-color: #04a188;
  color: white;
}
.statusNeutral {
  background-color: #fff;
  color: black;
}
