@import "../Variables/Variables.scss";

.docMainContainer {
  height: 100vh;
  width: 100%;
  display: flex;
}
.docSubContainer {
  padding: 10% 20%;
  align-items: center;
  display: flex;
  flex-direction: column;

  h1 {
    font-size: 54px;
    font-family: $titleFont;
    color: $TealColor;
    font-weight: 600;
  }

  p {
    font-size: 18px;
    line-height: 1.4;
    font-family: $bodyFont;
  }
}
