.mainContainer {
  background-color: rgb(253, 253, 253);
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: fixed;
  z-index: 10;
}
.subContainer {
  display: flex;
  width: 100%;
  max-width: 1100px;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0px;
}
.brandSection {
  display: flex;
  justify-content: center;
  align-items: center;
}
.brandLogo {
  display: flex;
  justify-content: center;
  align-items: center;
}
.brandName {
  font-weight: 600;
  font-size: 1.5rem;
  margin: 0px 10px;
}
.linkSection {
}
.linkSection a {
  margin-left: 50px;
  font-weight: 500;
  color: rgb(119, 119, 119);
}
.linkSection a:hover {
  color: #04a188;
  /* border-bottom: 4px solid #04a188; */
}
