.footerMainContainer {
  padding: 80px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #274f4f;
  color: white;
  font-weight: 400 !important;
}
.footerSubContainer {
  display: flex;
  flex-direction: column;
  width: 1200px;
}
.upperSection,
.lowerSection {
  display: grid;
  grid-template-columns: 30% 40% 30%;
  justify-content: space-between;
}
.pageLinks {
  display: flex;
  justify-content: space-evenly;
  align-items: flex-end;
}

.socialHandles {
  font-size: 30px;
  display: flex;
  justify-content: left;
  align-items: flex-end;
}
.socialIcon {
  margin-right: 20px;
}
.lowerSection .brandLogo {
  display: flex;
  justify-content: right;
  align-items: flex-end;
}
.contactDetails {
  display: flex;
  justify-content: center;
  align-items: center;
}
.contactDetails div {
  display: flex;
  justify-content: center;
  align-items: center;
}
.buttonDirect {
  display: flex;
  justify-content: right;
}
.footerButton {
  width: 15rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  background-color: #04a188;
  outline: none;
  border: none;
  border-radius: 5px;
  color: #fff;
}
.footerButton:hover {
  cursor: pointer;
}

.footerButton > a {
  cursor: pointer;
  color: white;
  font-weight: 500;
}
.footerBrandSection .heading {
  font-size: 1.5rem;
  font-weight: 600;
}
.footerBrandSection .tagLine {
  font-weight: 500;
  font-size: 1.1rem;
}
.footerBrandSection .subText {
  font-weight: 400;
}
